/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

html, body {
  font-size: 12px;
  box-sizing: border-box;
  border-collapse: collapse; }

*,
*:before,
*:after {
  box-sizing: inherit;
  border-collapse: inherit; }

a {
  text-decoration: none;
  color: inherit; }

a.visited {
  text-decoration: none;
  color: inherit; }

.flex {
  margin: 0 auto;
  padding: 1.5rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around; }

[hidden] {
  display: none !important; }

img {
  max-width: 100%;
  height: auto;
  -ms-interpolation-mode: bicubic;
  display: inline-block;
  vertical-align: middle; }

textarea {
  height: auto;
  min-height: 50px;
  border-radius: 3px; }

select {
  width: 100%;
  border-radius: 3px; }

button {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  padding: 0;
  border: 0;
  border-radius: 3px;
  line-height: 1; }

[type='text'], [type='password'], [type='date'], [type='datetime'], [type='datetime-local'], [type='month'], [type='week'], [type='email'], [type='number'], [type='search'], [type='tel'], [type='time'], [type='url'], [type='color'],
textarea {
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 2.4375rem;
  padding: 0.5rem;
  border: 1px solid #cacaca;
  margin: 0 0 1rem;
  font-family: inherit;
  font-size: 1rem;
  color: #0a0a0a;
  background-color: #fefefe;
  box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.1);
  border-radius: 3px;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: none; }

.row {
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  justify-content: space-around; }

.row .row {
  max-width: none;
  margin-left: -0.9375rem;
  margin-right: -0.9375rem; }

.row.expanded {
  max-width: none; }

.row.collapse > .column, .row.collapse > .columns {
  padding-left: 0;
  padding-right: 0; }

.column, .columns {
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-width: initial; }

.column.row.row, .row.row.columns {
  float: none;
  display: block; }

.row .column.row.row, .row .row.row.columns {
  padding-left: 0;
  padding-right: 0;
  margin-left: 0;
  margin-right: 0; }

.privacidad {
  margin: 0 auto;
  max-width: 960px;
  padding: 25px; }
  .privacidad h1 {
    font-size: 28px;
    margin: 50px auto; }
  .privacidad p {
    margin: 16px auto;
    font-size: 12px; }

form [type=submit], form [type=text], form [type=search], form [type=tel], form [type=time], form [type=url], form [type=color], form [type=password], form [type=date], form [type=datetime], form [type=datetime-local], form [type=month], form [type=week], form [type=email], form [type=number], form textarea, .form-citas [type=submit], .form-citas [type=text], .form-citas [type=search], .form-citas [type=tel], .form-citas [type=time], .form-citas [type=url], .form-citas [type=color], .form-citas [type=password], .form-citas [type=date], .form-citas [type=datetime], .form-citas [type=datetime-local], .form-citas [type=month], .form-citas [type=week], .form-citas [type=email], .form-citas [type=number], .form-citas textarea {
  color: #797979;
  background-color: #fff;
  border: 0;
  font-weight: bolder;
  font-size: 1.5rem;
  letter-spacing: .2rem;
  outline-style: none;
  padding: 10px 12px;
  text-transform: uppercase;
  box-shadow: none;
  border-radius: 0;
  border: 0;
  -webkit-transition-property: all;
  -moz-transition-property: all;
  -o-transition-property: all;
  transition-property: all;
  -webkit-transition-duration: 0.2s;
  -moz-transition-duration: 0.2s;
  -o-transition-duration: 0.2s;
  transition-duration: 0.2s;
  width: 100%;
  text-indent: .25em;
  outline-style: none;
  margin: 0 auto 10px auto; }

form [type=text]:hover,
form [type=search]:hover, form [type=tel]:hover, form [type=time]:hover, form [type=url]:hover, form [type=color]:hover,
form [type=password]:hover, form [type=date]:hover, form [type=datetime]:hover, form [type=datetime-local]:hover, form [type=month]:hover, form [type=week]:hover, form [type=email]:hover,
form [type=number]:hover, form textarea:hover, .form-citas [type=text]:hover,
.form-citas [type=search]:hover, .form-citas [type=tel]:hover, .form-citas [type=time]:hover, .form-citas [type=url]:hover, .form-citas [type=color]:hover,
.form-citas [type=password]:hover, .form-citas [type=date]:hover, .form-citas [type=datetime]:hover, .form-citas [type=datetime-local]:hover, .form-citas [type=month]:hover, .form-citas [type=week]:hover, .form-citas [type=email]:hover,
.form-citas [type=number]:hover, .form-citas textarea:hover {
  background-color: #999;
  color: #fff; }

form [type=submit], .form-citas [type=submit] {
  background-color: #212121;
  color: #fff;
  cursor: pointer; }

form [type=submit]:hover, .form-citas [type=submit]:hover {
  background-color: #999;
  color: #fff; }

form ::-webkit-input-placeholder, .form-citas ::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #979797; }

form ::-moz-placeholder, .form-citas ::-moz-placeholder {
  /* Firefox 19+ */
  color: #979797; }

form :-ms-input-placeholder, .form-citas :-ms-input-placeholder {
  /* IE 10+ */
  color: #979797; }

form :-moz-placeholder, .form-citas :-moz-placeholder {
  /* Firefox 18- */
  color: #979797; }

form input:focus::-webkit-input-placeholder, form input:hover::-webkit-input-placeholder, .form-citas input:focus::-webkit-input-placeholder, .form-citas input:hover::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #fff; }

form textarea:focus::-webkit-input-placeholder, form textarea:hover::-webkit-input-placeholder, .form-citas textarea:focus::-webkit-input-placeholder, .form-citas textarea:hover::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #fff; }

form input:focus::-moz-placeholder, form input:hover::-moz-placeholder, .form-citas input:focus::-moz-placeholder, .form-citas input:hover::-moz-placeholder {
  /* Firefox 19+ */
  color: #fff; }

form textarea:focus::-moz-placeholder, form textarea:hover::-moz-placeholder, .form-citas textarea:focus::-moz-placeholder, .form-citas textarea:hover::-moz-placeholder {
  /* Firefox 19+ */
  color: #fff; }

form input:focus:-ms-input-placeholder, form input:hover:-ms-input-placeholder, .form-citas input:focus:-ms-input-placeholder, .form-citas input:hover:-ms-input-placeholder {
  /* IE 10+ */
  color: #fff; }

form textarea:focus:-ms-input-placeholder, form textarea:hover:-ms-input-placeholder, .form-citas textarea:focus:-ms-input-placeholder, .form-citas textarea:hover:-ms-input-placeholder {
  /* IE 10+ */
  color: #fff; }

form input:focus:-moz-placeholder, form input:hover:-moz-placeholder, .form-citas input:focus:-moz-placeholder, .form-citas input:hover:-moz-placeholder {
  /* Firefox 18- */
  color: #fff; }

form textarea:focus:-moz-placeholder, form textarea:hover:-moz-placeholder, .form-citas textarea:focus:-moz-placeholder, .form-citas textarea:hover:-moz-placeholder {
  /* Firefox 18- */
  color: #fff; }

form label, .form-citas label {
  display: none; }

form textarea, .form-citas textarea {
  resize: none;
  height: 6.4375rem; }

.overlay {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.33);
  height: 100%;
  width: 100%;
  top: 0;
  left: 0; }

body {
  font-family: 'Inconsolata', monospace;
  color: #fff;
  font-size: 1.3rem;
  line-height: 1.3; }

h1, h2, h3 {
  text-align: center; }

h1 {
  font-size: 2.5rem; }

h2 {
  font-size: 2rem; }

.hero, .bg {
  margin: 0;
  padding: 0;
  background: url("../img/sala-minimalista-a-la-medida.jpg") no-repeat;
  background-position: center center;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  height: 90vh;
  width: 100%;
  text-align: center; }

.flex {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  align-content: stretch;
  padding: 0;
  margin: 0; }
  @media (min-width: 768px) {
    .flex {
      flex-wrap: nowrap; } }

.col {
  align-self: center;
  position: relative;
  width: 100%; }

section {
  padding: 70px 50px; }

footer {
  background-color: #000;
  height: 20vh; }

.bg-red {
  background-color: #8D191A; }

.bg-black {
  background-color: #000; }

.hero {
  padding-top: 50px; }
  .hero h2 {
    color: #8D191A; }
  .hero h1 {
    color: #000;
    text-transform: uppercase; }

@media (min-width: 768px) {
  .exp h2 {
    font-size: 3rem; }
  .exp h1 {
    font-size: 3.5rem; } }

@media (min-width: 992px) {
  .exp p, .exp .col {
    font-size: 2rem; } }

@media (min-width: 1200px) {
  .exp p, .exp .col {
    font-size: 2.5rem; } }

.exp .caption {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  padding: 10px;
  margin: auto;
  display: flex;
  justify-content: center; }

.exp-1, .exp-3 {
  padding: 0; }
  .exp-1 .img-side, .exp-3 .img-side {
    padding: 30px; }
    .exp-1 .img-side img, .exp-3 .img-side img {
      display: block;
      margin: auto; }
  .exp-1 .side, .exp-3 .side {
    padding: 20px 50px; }

@media (min-width: 768px) {
  .exp-1 .col.img-side {
    max-width: 100%;
    min-width: 500px; } }

.exp-3 .img-side {
  max-width: 60%;
  overflow: hidden;
  min-width: 300px; }
  @media (min-width: 992px) {
    .exp-3 .img-side img {
      width: 50%; } }

.exp-2 .col {
  min-width: 300px;
  max-width: 700px; }
  .exp-2 .col img {
    width: 100%; }

.exp-2 .caption {
  background-color: rgba(0, 0, 0, 0.6);
  height: 220px;
  max-width: 600px;
  padding: 10px 30px; }
  .exp-2 .caption p {
    align-self: center; }

.exp-4 {
  background-image: url("../img/muebles-a-la-medida.jpg");
  height: 40vh; }
  @media (min-width: 768px) {
    .exp-4 {
      height: 70vh; } }
  .exp-4 .flex {
    height: 100%; }
  .exp-4 .col {
    align-self: flex-end;
    margin-bottom: 30px; }
    .exp-4 .col p {
      width: 80%;
      margin: auto;
      display: block;
      padding: 2% 0; }

.exp-5 h2 {
  margin: 20px auto 20px auto; }

@media (min-width: 768px) {
  .exp-5 .gallery .flex {
    flex-wrap: wrap;
    margin: auto; }
    .exp-5 .gallery .flex .col {
      flex: 0 1 auto;
      align-self: flex-start;
      padding: 0; }
      .exp-5 .gallery .flex .col:nth-child(1) {
        width: 56%; }
      .exp-5 .gallery .flex .col:nth-child(2) {
        width: 40%; }
      .exp-5 .gallery .flex .col .another .caption {
        right: 20px;
        left: inherit; }
      .exp-5 .gallery .flex .col .another.another-1:nth-child(1) .caption, .exp-5 .gallery .flex .col .another.another-1:nth-child(2) .caption {
        left: 0; } }

@media (min-width: 992px) {
  .exp-5 .gallery .flex {
    width: 70%; } }

.exp-5 .gallery .flex .col .another .col, .exp-5 .gallery .flex .col .another img {
  width: 100%; }

.exp-5 .gallery .flex .col .another .col .caption {
  top: inherit;
  bottom: 20px;
  height: auto;
  width: 150px;
  text-align: center; }

@media (min-width: 768px) {
  .contacto .col {
    width: 50%; } }

.living-form {
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  color: #000; }
  .living-form input[type="text"],
  .living-form input[type="email"],
  .living-form input[type="tel"],
  .living-form input[type="url"],
  .living-form textarea,
  .living-form button[type="submit"] {
    font-size: 1.3rem;
    color: #fff;
    text-transform: capitalize; }
  @media (min-width: 768px) {
    .living-form h2 {
      font-size: 3rem; }
    .living-form input[type="text"],
    .living-form input[type="email"],
    .living-form input[type="tel"],
    .living-form input[type="url"],
    .living-form textarea,
    .living-form button[type="submit"] {
      font-size: 2rem; } }
  .living-form h2 {
    margin: 20px auto; }
  .living-form label span {
    cursor: pointer;
    color: #991D57;
    display: block;
    margin: 5px 0;
    font-weight: 900; }
  .living-form input[type="text"],
  .living-form input[type="email"],
  .living-form input[type="tel"],
  .living-form input[type="url"],
  .living-form textarea {
    width: 100%;
    box-shadow: inset 0 1px 2px #DDD, 0 1px 0 transparent;
    -webkit-box-shadow: inset 0 1px 2px #DDD, 0 1px 0 transparent;
    -moz-box-shadow: inset 0 1px 2px #DDD, 0 1px 0 transparent;
    border: 1px solid #8D191A;
    background: #8D191A;
    margin: 2rem auto;
    padding: 18px;
    border-radius: 2rem; }
  .living-form input[type="text"]:hover,
  .living-form input[type="email"]:hover,
  .living-form input[type="tel"]:hover,
  .living-form input[type="url"]:hover,
  .living-form textarea:hover {
    -webkit-transition: border-color 0.3s ease-in-out;
    -moz-transition: border-color 0.3s ease-in-out;
    transition: border-color 0.3s ease-in-out;
    border: 1px solid #AAA; }
  .living-form textarea {
    height: 100px;
    max-width: 100%; }
  .living-form button[type="submit"], .living-form input[type="submit"] {
    cursor: pointer;
    width: 50%;
    float: right;
    border: none;
    background: #000;
    color: #FFF;
    margin: 2rem auto;
    padding: 18px;
    border-radius: 2rem;
    text-align: center;
    font-size: 1.3rem; }
  .living-form button[type="submit"]:hover, .living-form input[type="submit"]:hover {
    -webkit-transition: background 0.3s ease-in-out;
    -moz-transition: background 0.3s ease-in-out;
    transition: background-color 0.3s ease-in-out; }
  .living-form button[type="submit"]:active, .living-form input[type="submit"]:active {
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.5); }
  .living-form input:focus,
  .living-form textarea:focus {
    outline: 0;
    border: 1px solid #999; }
  .living-form ::-webkit-input-placeholder {
    color: #fff; }
  .living-form :-moz-placeholder {
    color: #fff; }
  .living-form ::-moz-placeholder {
    color: #fff; }
  .living-form :-ms-input-placeholder {
    color: #fff; }
