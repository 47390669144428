.living-form{
	border-radius:4px;
	-webkit-border-radius:4px;
	-moz-border-radius:4px;
    color: #000;

input[type="text"],
input[type="email"],
input[type="tel"],
input[type="url"],
textarea,
button[type="submit"] {
	font-size: 1.3rem;
    color:#fff;
    text-transform: capitalize;
}
@media(min-width:768px){
  h2{font-size: 3rem;}  
  input[type="text"],
input[type="email"],
input[type="tel"],
input[type="url"],
textarea,
button[type="submit"] {
	font-size: 2rem;
}
}
h2 {
     margin:20px auto;

}

label span {
	cursor:pointer;
	color:#991D57;
	display:block;
	margin:5px 0;
	font-weight:900;
}
input[type="text"],
input[type="email"],
input[type="tel"],
input[type="url"],
textarea {
	width:100%;
	box-shadow:inset 0 1px 2px #DDD, 0 1px 0 transparent;
	-webkit-box-shadow:inset 0 1px 2px #DDD, 0 1px 0 transparent;
	-moz-box-shadow:inset 0 1px 2px #DDD, 0 1px 0 transparent;
	border:1px solid #8D191A;
	background:#8D191A;
	margin:2rem auto;
	padding:18px;
	border-radius:2rem;
}
input[type="text"]:hover,
input[type="email"]:hover,
input[type="tel"]:hover,
input[type="url"]:hover,
textarea:hover {
	-webkit-transition:border-color 0.3s ease-in-out;
	-moz-transition:border-color 0.3s ease-in-out;
	transition:border-color 0.3s ease-in-out;
	border:1px solid #AAA;
}
textarea {
	height:100px;
	max-width:100%;
}
button[type="submit"] ,input[type="submit"] {
	cursor:pointer;
	width:50%;
    float: right;
	border:none;
	background:#000;
	color:#FFF;
	margin:2rem auto;
	padding:18px;
	border-radius:2rem;
	text-align: center;
    font-size: 1.3rem;
}
button[type="submit"]:hover,input[type="submit"]:hover {
	-webkit-transition:background 0.3s ease-in-out;
	-moz-transition:background 0.3s ease-in-out;
	transition:background-color 0.3s ease-in-out;
}
button[type="submit"]:active,input[type="submit"]:active {
	box-shadow:inset 0 1px 3px rgba(0,0,0,0.5);
}
input:focus,
textarea:focus {
	outline:0;
	border:1px solid #999;
}
::-webkit-input-placeholder {
    color:#fff;
}
:-moz-placeholder {
    color:#fff;
}
::-moz-placeholder {
    color:#fff;
}
:-ms-input-placeholder {
    color:#fff;
}
}
