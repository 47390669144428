html, body {   
    font-size:  12px;
    box-sizing: border-box;
    border-collapse: collapse;
}

*,
*:before,
*:after {
  box-sizing: inherit;
  border-collapse: inherit;
}



a{
    text-decoration: none;
    color: inherit;
}

a.visited {
    text-decoration: none;
    color: inherit;
}

.flex{
    margin: 0 auto;
    padding: 1.5rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
}


// Makes the hidden attribute works even when an element is styled display: flex
[hidden] {display: none !important}


img {
  max-width: 100%;
  height: auto;
  -ms-interpolation-mode: bicubic;
  display: inline-block;
  vertical-align: middle; }

textarea {
  height: auto;
  min-height: 50px;
  border-radius: 3px; }

select {
  width: 100%;
  border-radius: 3px; }


  button {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  padding: 0;
  border: 0;
  border-radius: 3px;
  line-height: 1; }

  [type='text'], [type='password'], [type='date'], [type='datetime'], [type='datetime-local'], [type='month'], [type='week'], [type='email'], [type='number'], [type='search'], [type='tel'], [type='time'], [type='url'], [type='color'],
textarea {
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 2.4375rem;
  padding: 0.5rem;
  border: 1px solid #cacaca;
  margin: 0 0 1rem;
  font-family: inherit;
  font-size: 1rem;
  color: #0a0a0a;
  background-color: #fefefe;
  box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.1);
  border-radius: 3px;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: none; }

  .row {
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-flex-flow: row wrap;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
    justify-content: space-around; }
  .row .row {
    max-width: none;
    margin-left: -0.9375rem;
    margin-right: -0.9375rem; }
  .row.expanded {
    max-width: none; }
  .row.collapse > .column, .row.collapse > .columns {
    padding-left: 0;
    padding-right: 0; }

.column, .columns {
  -webkit-flex: 1 1 auto;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-width: initial; }

.column.row.row, .row.row.columns {
  float: none;
  display: block; }
  .row .column.row.row, .row .row.row.columns {
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
    margin-right: 0; }

  .privacidad{
    margin: 0 auto;
    max-width: 960px;
    padding: 25px;

    h1{
      font-size: 28px;
      margin: 50px auto;
    }

    p{
      margin: 16px auto;
      font-size: 12px;
    }
  }

form,.form-citas{
   [type=submit] ,[type=text], [type=search], [type=tel], [type=time], [type=url], [type=color], [type=password], [type=date], [type=datetime], [type=datetime-local], [type=month], [type=week], [type=email], [type=number], textarea{
    color: #797979;
    background-color: #fff;
    border: 0;
    font-weight: bolder;
    font-size: 1.5rem;
    letter-spacing: .2rem;
    outline-style: none;
    padding: 10px 12px;
    text-transform: uppercase;
    box-shadow: none;
    border-radius: 0;
    border: 0;
    -webkit-transition-property: all;
    -moz-transition-property: all;
    -o-transition-property: all;
    transition-property: all;
    -webkit-transition-duration: 0.2s;
    -moz-transition-duration: 0.2s;
    -o-transition-duration: 0.2s;
    transition-duration: 0.2s;
    width: 100%;
    text-indent: .25em;
    outline-style: none;
    margin: 0 auto 10px auto;
 }
  [type=text]:hover,
   [type=search]:hover, [type=tel]:hover, [type=time]:hover, [type=url]:hover, [type=color]:hover,
    [type=password]:hover, [type=date]:hover, [type=datetime]:hover, [type=datetime-local]:hover, [type=month]:hover, [type=week]:hover, [type=email]:hover,
     [type=number]:hover, textarea:hover{
      background-color: #999;
      color: #fff;

  }
   [type=submit]{
        background-color: #212121;
      color: #fff;
      cursor:pointer;
   }
 [type=submit]:hover{
    background-color: #999;
      color: #fff;
   }
  ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #979797;
}
::-moz-placeholder { /* Firefox 19+ */
  color: #979797;
}
:-ms-input-placeholder { /* IE 10+ */
  color: #979797;
}
:-moz-placeholder { /* Firefox 18- */
  color: #979797;
}


input:focus::-webkit-input-placeholder,input:hover::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #fff;
}
textarea:focus::-webkit-input-placeholder,textarea:hover::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #fff;
}
input:focus::-moz-placeholder,input:hover::-moz-placeholder { /* Firefox 19+ */
  color: #fff;
}
textarea:focus::-moz-placeholder,textarea:hover::-moz-placeholder { /* Firefox 19+ */
  color: #fff;
}
input:focus:-ms-input-placeholder ,input:hover:-ms-input-placeholder { /* IE 10+ */
  color: #fff;
}
textarea:focus:-ms-input-placeholder ,textarea:hover:-ms-input-placeholder { /* IE 10+ */
  color: #fff;
}
input:focus:-moz-placeholder,input:hover:-moz-placeholder { /* Firefox 18- */
  color: #fff;
}
textarea:focus:-moz-placeholder,textarea:hover:-moz-placeholder { /* Firefox 18- */
  color: #fff;
}
 label{
     display: none;
 }
 textarea{
     resize: none;
     height: 6.4375rem;
 }
    
}
.overlay {
    position: absolute;
    background-color: rgba(0,0,0,.33);
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
}