body {
    font-family: 'Inconsolata', monospace;
    color:#fff;
    font-size: 1.3rem;
    line-height: 1.3;
}
h1,h2,h3{text-align: center}
h1{font-size: 2.5rem;}
h2{font-size: 2rem;}

.hero, .bg{
    margin: 0;
    padding:0;
    background: url('../img/sala-minimalista-a-la-medida.jpg') no-repeat;
    background-position: center center;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    height: 90vh;
    width: 100%;
    text-align: center;
    
}
.flex{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: stretch;
    align-content:stretch;
    padding: 0;
    margin: 0;
    @media(min-width:768px){
            flex-wrap: nowrap;

    }
}
.col{
    align-self: center;
    position: relative;
    width: 100%;
}
section{padding: 70px 50px;}

footer{
    background-color:#000;
    height: 20vh;
}
.bg-red{
        background-color:#8D191A;

}
.bg-black{
        background-color:#000;

}
.hero{
    padding-top: 50px;
    h2{color:#8D191A}
    h1{color:#000;text-transform: uppercase;}
 
}

.exp {
 @media(min-width:768px){
        h2{font-size: 3rem;}
        h1{font-size: 3.5rem;}
    }
 @media(min-width:992px){
        p,.col{font-size: 2rem;}

 }
  @media(min-width:1200px){
        p,.col{font-size: 2.5rem;}

 }
    .caption{
        position:absolute;
        top:0;
        bottom: 0;
        right: 0;
        left:0;
        padding: 10px;
        margin:auto;
        display: flex;
        justify-content: center;
    }
 
}

.exp-1,.exp-3{
    padding: 0;
    .img-side{
        padding: 30px;
        img{
            display: block;
            margin: auto;
        }

    }
    .side{
        padding: 20px 50px;
    }  
   
    }
.exp-1{
 @media(min-width:768px){
        .col.img-side {
    max-width: 100%;
    min-width: 500px;
}
 }
}
.exp-3{
    .img-side {
    max-width: 60%;
    overflow: hidden;
    min-width: 300px;
    @media(min-width:992px){
        img{width: 50%;}
    }
}
}
.exp-2{
    .col{min-width: 300px;max-width: 700px;
    img{width: 100%;}
    }
    .caption{
        background-color: rgba(0,0,0,.6);
        height: 220px;
        max-width: 600px;
        padding: 10px 30px;

        p{
           align-self: center;
        }
    }
}
.exp-4{
    background-image: url('../img/muebles-a-la-medida.jpg');
    height: 40vh;
    @media(min-width:768px){
    height: 70vh;

    }
    .flex{
    height: 100%;

    }
    .col{
     align-self: flex-end;
    margin-bottom: 30px;


    p{
        width:80%;
        margin: auto;
        display: block;
        padding: 2% 0;
    }
    }

}
.exp-5{
    h2{margin: 20px auto 20px auto}
   
    .gallery{

    .flex{
        @media(min-width:768px){
           flex-wrap: wrap;
            margin: auto;
            .col{
            flex: 0 1 auto;
            align-self: flex-start;
            padding: 0;
            &:nth-child(1){width:56%;}
            &:nth-child(2){width:40%}
            .another{
             .caption{
                    right: 20px;
                    left: inherit;

                    }
              &.another-1 {
                  &:nth-child(1),&:nth-child(2){
                            .caption{
                            left: 0;
                            }
                        } 
                        
                    }
                }
            }
        }        
        @media(min-width:992px){
            width: 70%;

        }
            
        .col{


            .another{
                .col, img{width: 100%;}
                .col{
                    .caption{
                        top: inherit;
                        bottom: 20px;
                        height: auto;
                        width: 150px;
                        text-align: center;
                    }                

                }

            }

         
            }
    }

}
}
.contacto{
    @media(min-width:768px){
        .col{width: 50%;}
    }
}